import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Yolanda | Developer', // e.g: 'Name | Developer'
  lang: 'En', // e.g: en, es, fr, jp
  description: 'Welcome to my website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi! My name is',
  name: 'Yolanda',
  subtitle: `I'm a Software Engineer who loves to collaborate, helping people who wants to get into tech, and enjoys meeting amazing individuals!`,
  cta: 'Know more!',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile-pic.jpg',
  paragraphOne: `Hi there! My name is Yolanda and I made a career tranisition from COTA (certified occupational therapy assistant) to FULL STACK Software Engineer! My current stack MERN (MongoDB, Express, React, Node) stack.`,
  paragraphTwo: `Most of my life I have been tinkering with tech through playing with different linux OS, being curious about what a computer BIOS is, and learning how to code. After being in the healthcare field for 5+ years, I wanted to make a bigger impact helping people and also give more respresentation for females and POC in the tech field.`,
  paragraphThree: `In the past I was told that you had to be a genius to make it as a Software Engineer and that steered me away from it. But now that I've made it, I want to empower people that you don't have to be a genius, that consistency and hard work matters more than being 'smart'. I would love to combine my two passions, as a therapist and a developer, and use tech to help those in need.`,
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'landing-page-bookstache.png',
    title: 'Bookstache',
    info: `Bookstache is an app that allows others to check out your current list of books you own!`,
    info2: `Share it with family and friends so they can see what you currently own and what your favorite books are.`,
    url: 'https://bookstache.herokuapp.com/',
    repo: 'https://github.com/YolandaHaynes/bookstache', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'woot-woot.jpg',
    title: 'Woot Woot',
    info: 'A collab lab team project of a smart shopping list app',
    info2: `The app is a smart shoping list app where a user can keep track of their items, share it with family, and the app suggests when the next item will need to be purchased by.`,
    url: 'https://wootwoot.netlify.app/',
    repo: 'https://github.com/the-collab-lab/tcl-27-smart-shopping-list', // if no repo, the button will not show up
  },
  // {
  //   id: nanoid(),
  //   img: 'travel-app.png',
  //   title: 'Travel App',
  //   info: 'A full stack app that displays traveling photos of the users.',
  //   info2: 'The app implements a sign-in feature with features of adding/deleting photos and liking photos.',
  //   url: ' https://lit-plateau-90992.herokuapp.com/',
  //   repo: 'https://github.com/YolandaHaynes/teamproject', // if no repo, the button will not show up
  // },
  // {
  //   id: nanoid(),
  //   img: 'project.jpg',
  //   title: '',
  //   info: '',
  //   info2: '',
  //   url: '',
  //   repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  // },
];

// CONTACT DATA
export const contactData = {
  cta: 'Would you like to work with me? Or have a question?',
  btn: `Let's talk!`,
  email: 'yhaynes@giyo.us',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/_YolandaHaynes',
    },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/yolanda-haynes/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/YolandaHaynes',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
